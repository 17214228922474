import { IDIOMA_DESCONOCIDO, IDIOMA_ESPAÑOL, IDIOMA_INGLES } from "constantes";
import IdiomaContext from "idiomaContext";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { todosLosCursos } from "texto";

export default function Menu() {
  const {idioma, setIdioma} = useContext(IdiomaContext);

  const cambiarIdioma = (nuevoIdioma) => {
    if (nuevoIdioma === idioma){
      setIdioma(IDIOMA_DESCONOCIDO);
    } else{
      setIdioma(nuevoIdioma);
    }
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          Felipe Gavilán
        </NavLink>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" to="/cursos">
                {todosLosCursos(idioma)}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/cursos/asp-net-core">
                ASP.NET Core
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/cursos/csharp">
                .NET/C#
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/cursos/react">
                React
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/cursos/angular">
                Angular
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/cursos/entity-framework-core">
                Entity Framework Core
              </NavLink>
            </li>
          </ul>
          
          <span className={`nav-link pointer ${(idioma === IDIOMA_ESPAÑOL ? 'idioma-seleccionado' : '')}`} 
          onClick={() => {
            cambiarIdioma(IDIOMA_ESPAÑOL);
          }}>Español</span>
          <span className="navbar-text">/</span>
          <span className={`nav-link pointer ${(idioma === IDIOMA_INGLES ? 'idioma-seleccionado' : '')}`} 
          onClick={() => {
            cambiarIdioma(IDIOMA_INGLES);
          }}>English</span>
        </div>
      </div>
    </nav>
  );
}
