import { IDIOMA_DESCONOCIDO } from "constantes";
import IdiomaContext from "idiomaContext";
import React, { useContext, useEffect, useState } from "react";
import { categoriaVacia } from "texto";
import Curso from "./Curso";

export default function ListadoCursos(props) {
  const [cursos, setCursos] = useState(props.listado);
  const [mensaje, setMensaje] = useState("");
  const { idioma } = useContext(IdiomaContext);

  useEffect(() => {
    setMensaje('');
    const filtro = props.filtro;
    let filtrados = props.listado;
    if (filtro) {
      filtrados = props.listado.filter((curso) =>
        curso.etiquetas?.includes(filtro)
      );
    }

    if (filtrados.length === 0) {
      filtrados = props.listado;
      setMensaje(categoriaVacia(idioma));
    }

    if (idioma !== IDIOMA_DESCONOCIDO) {
      filtrados = filtrados.filter((curso) => curso.idioma === idioma);
    }

    setCursos(filtrados);
  }, [props.filtro, props.listado, idioma]);

  return (
    <>
    <h3>{mensaje}</h3>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {cursos.map((curso) => (
          <div key={curso.titulo} className="col">
            <Curso {...curso} />
          </div>
        ))}
      </div>
    </>
  );
}
