import React from "react";
import { comprar } from "texto";
import obtenerLink from "./obtenerLinkConCupones";

export default function Curso(props) {
  return (
    <div className="card h-100">
      <img src={props.imagenPrincipal} className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title">{props.titulo}</h5>
        <p className="card-text">{props.descripcion}</p>
      </div>
      <div className="card-footer">
        <a
          href={obtenerLink(props.linkUdemy, props.idioma)}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary"
        >
          {comprar(props.idioma)}
        </a>
      </div>
    </div>
  );
}
