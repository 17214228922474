import { IDIOMA_DESCONOCIDO } from "constantes";
import Footer from "Footer";
import IdiomaContext from "idiomaContext";
import Menu from "Menu";
import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import routes from "./route-config";

function App() {
  const [idioma, setIdioma] = useState(IDIOMA_DESCONOCIDO);
  return (
    <BrowserRouter>
      <IdiomaContext.Provider value={{ idioma, setIdioma }}>
        <Menu />
        <div className="container">
          <Switch>
            {routes.map((route) => (
              <Route key={route.path} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
          </Switch>
        </div>
        <Footer />
      </IdiomaContext.Provider>
    </BrowserRouter>
  );
}

export default App;
