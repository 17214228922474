import { IDIOMA_ESPAÑOL, IDIOMA_INGLES } from "constantes";

const cursosDatos = [
  {
    titulo: "Introducción a Entity Framework Core 6 - De Verdad",
    descripcion:
      "Aprende desde cero Entity Framework Core 6 para trabajar con bases de datos en C#/ASP.NET Core.",
    idioma: IDIOMA_ESPAÑOL,
    linkUdemy:
      "https://www.udemy.com/course/introduccion-a-entity-framework-core-2-1-de-verdad",
    imagenPrincipal:
      "/portadas/ef-core.jpg",
      etiquetas: ['entity-framework-core'],
      prioridad: 2
  },
  {
    titulo: "Construyendo Web APIs RESTful con ASP.NET Core 6",
    descripcion:
      "¡Aprende a construir y publicar cualquier Web API que te propongas!",
    idioma: IDIOMA_ESPAÑOL,
    linkUdemy:
      "https://www.udemy.com/course/construyendo-web-apis-restful-con-aspnet-core",
    imagenPrincipal:
      "/portadas/web-apis-esp.jpg",
      etiquetas: ['asp-net-core', 'entity-framework-core'],
      prioridad: 3
  },
  {
    titulo: "Diseño y Programación Orientada a Objetos en C#",
    descripcion:
    "Aprende principios de desarrollo de software para construir mejores aplicaciones.",
    idioma: IDIOMA_ESPAÑOL,
    linkUdemy:
      "https://www.udemy.com/course/diseno-y-programacion-orientada-a-objetos-en-c/",
    etiquetas: ['csharp'],
    imagenPrincipal: '/portadas/oop-poo-cs.jpg',
    prioridad: 1
  },
  {
    titulo: "Programando en Blazor - ASP.NET Core 7",
    descripcion: 'Crea aplicaciones web interactivas con C#',
    idioma: IDIOMA_ESPAÑOL,
    etiquetas: ['asp-net-core', 'blazor', 'entity-framework-core'],
    linkUdemy:
      "https://www.udemy.com/course/programando-en-blazor-aspnet-core",
    imagenPrincipal: '/portadas/blazor.jpg',
    prioridad: 3
  },
  {
    titulo: "Programming in Blazor - ASP.NET Core 5",
    descripcion: 'Create interactive web applications with C#',
    idioma: IDIOMA_INGLES,
    etiquetas: ['asp-net-core', 'blazor', 'entity-framework-core'],
    linkUdemy:
      "https://www.udemy.com/course/programming-in-blazor-aspnet-core/",
    imagenPrincipal: '/portadas/blazor.jpg',
    prioridad: 3
  },
  {
    titulo: "Building RESTful Web APIs with ASP.NET Core 3.1",
    descripcion: 'Learn how to build and deploy an ASP.NET Core Web API!',
    idioma: IDIOMA_INGLES,
    etiquetas: ['asp-net-core', 'entity-framework-core'],
    linkUdemy:
      "https://www.udemy.com/course/building-restful-web-apis-with-aspnet-core/",
    imagenPrincipal: '/portadas/web-apis-eng.jpg',
    prioridad: 1
  },
  {
    titulo: "Introducción a la Concurrencia en C# - Async y Paralelismo",
    descripcion: 'Aprende a desarrollar aplicaciones escalables y rápidas utilizando concurrencia de manera efectiva.',
    idioma: IDIOMA_ESPAÑOL,
    etiquetas: ['csharp'],
    linkUdemy:
      "https://www.udemy.com/course/introduccion-a-la-concurrencia-en-c-async-y-paralelismo",
    imagenPrincipal: '/portadas/concurrencia.jpg',
    prioridad: 1
  },
  {
    titulo: "Introduction to Concurrency in C# - Async and Paralellism",
    descripcion: 'Learn to develop scalable and fast applications using concurrency.',
    idioma: IDIOMA_INGLES,
    etiquetas: ['csharp'],
    linkUdemy:
      "https://www.udemy.com/course/introduction-to-concurrency-in-c-async-and-paralellism/",
    imagenPrincipal: '/portadas/concurrencia.jpg',
    prioridad: 1
  },
  {
    titulo: "Building Applications with Angular 11 and ASP.NET Core 5",
    descripcion: 'Use Angular, ASP.NET Core, Entity Framework Core, Material Design, JWT, Leaflet, for developing a complete application.',
    idioma: IDIOMA_INGLES,
    etiquetas: ['asp-net-core', 'angular', 'entity-framework-core'],
    linkUdemy:
      "https://www.udemy.com/course/building-applications-with-angular-and-aspnet-core/",
    imagenPrincipal: '/portadas/asp-net-core-angular.jpg',
    prioridad: 3
  },
  {
    titulo: "Desarrollando Aplicaciones en React 17 y ASP.NET Core 5",
    descripcion: 'Utiliza React, Hooks, ASP.NET Core, Entity Framework Core, Bootstrap, JWT, Leaflet, para crear una aplicación completa.',
    idioma: IDIOMA_ESPAÑOL,
    etiquetas: ['asp-net-core', 'react', 'entity-framework-core'],
    linkUdemy:
      "https://www.udemy.com/course/desarrollando-aplicaciones-en-react-y-aspnet-core",
    imagenPrincipal: '/portadas/asp-net-core-react.jpg',
    prioridad: 3
  },
  {
    titulo: "Building Applications with React 17 and ASP.NET Core 6",
    descripcion: 'Use React, Hooks, ASP.NET Core, Entity Framework Core, Bootstrap, Leaflet and JWT to create a complete web application.',
    idioma: IDIOMA_INGLES,
    etiquetas: ['asp-net-core', 'react', 'entity-framework-core'],
    linkUdemy:
      "https://www.udemy.com/course/building-applications-with-react-and-aspnet-core/",
    imagenPrincipal: '/portadas/asp-net-core-react.jpg',
    prioridad: 4
  },
  {
    titulo: "Ejemplos de LINQ con C# 10",
    descripcion: 'Aprende LINQ haciendo ejemplos, tanto la sintaxis de métodos como la sintáxis de queries.',
    idioma: IDIOMA_ESPAÑOL,
    etiquetas: ['linq', 'csharp', 'entity-framework-core'],
    linkUdemy:
      "https://www.udemy.com/course/ejemplos-de-linq-con-csharp/",
    imagenPrincipal: '/portadas/ejemplos-linq.jpg',
    prioridad: 3
  },
  {
    titulo: "Aprende ASP.NET Core MVC 6 Haciendo Proyectos Desde Cero",
    descripcion: 'Aprende ASP.NET Core realizando varios proyectos.',
    idioma: IDIOMA_ESPAÑOL,
    etiquetas: ['asp-net-core', 'csharp', 'entity-framework-core'],
    linkUdemy:
      "https://www.udemy.com/course/aprende-aspnet-core-mvc-haciendo-proyectos-desde-cero/",
    imagenPrincipal: '/portadas/asp-net-core-mvc.png',
    prioridad: 4
  },
  {
    titulo: "Introduction to Entity Framework Core 6",
    descripcion:
      "Learn Entity Framework Core from scratch so that you can work with databases using C#/ASP.NET Core.",
    idioma: IDIOMA_INGLES,
    linkUdemy:
      "https://www.udemy.com/course/introduction-to-entity-framework-core",
    imagenPrincipal:
      "/portadas/ef-core.jpg",
      etiquetas: ['entity-framework-core'],
      prioridad: 4
  },
  {
    titulo: "Programando en C# 10 - De Principiante a Profesional",
    descripcion: 'Aprende C# viendo videos, contestando preguntas y resolviendo problemas.',
    idioma: IDIOMA_ESPAÑOL,
    etiquetas: ['csharp'],
    linkUdemy:
      "https://www.udemy.com/course/programando-en-csharp-de-principiante-a-profesional/",
    imagenPrincipal: '/portadas/csharp.png',
    prioridad: 4
  },
  {
    titulo: "Programming in C# 11 - From Beginner to Advanced",
    descripcion: 'Learn C# to develop applications!',
    idioma: IDIOMA_INGLES,
    etiquetas: ['csharp'],
    linkUdemy:
      "https://www.udemy.com/course/programming-in-csharp-from-beginner-to-advanced/",
    imagenPrincipal: '/portadas/csharp.png',
    prioridad: 4
  },
  {
    titulo: "Construyendo Minimal APIs con ASP.NET Core 8 y EF Core",
    descripcion:
      "¡ASP.NET Core, Web APIs, SQL Server, Sistema de Usuarios, GraphQL, Redis, REST, y mucho más!",
    idioma: IDIOMA_ESPAÑOL,
    linkUdemy:
      "https://www.udemy.com/course/construyendo-minimal-apis-con-aspnet-core-y-ef-core",
    imagenPrincipal:
      "/portadas/minimal-apis-ef-core.jpg",
      etiquetas: ['asp-net-core', 'entity-framework-core'],
      prioridad: 3
  },
  {
    titulo: "Construyendo Minimal APIs con ASP.NET Core 8 y Dapper",
    descripcion:
      "¡ASP.NET Core, Web APIs, SQL Server, Procedimientos Almacenados, Sistema de Usuarios, Redis, REST, y mucho más!",
    idioma: IDIOMA_ESPAÑOL,
    linkUdemy:
      "https://www.udemy.com/course/construyendo-minimal-apis-con-aspnet-core-8-y-dapper",
    imagenPrincipal:
      "/portadas/minimal-apis-ef-dapper.jpg",
      etiquetas: ['asp-net-core', 'ADO.NET'],
      prioridad: 3
  }, {
    titulo: "Building Minimal APIs with ASP.NET Core 8 and EF Core",
    descripcion:
      "ASP.NET Core, Web APIs, SQL Server, User System, GraphQL, Redis, REST, and more!",
    idioma: IDIOMA_INGLES,
    linkUdemy:
      "https://www.udemy.com/course/building-minimal-apis-with-aspnet-core-and-ef-core",
    imagenPrincipal:
      "/portadas/minimal-apis-ef-core-eng.png",
      etiquetas: ['asp-net-core', 'entity-framework-core'],
      prioridad: 3
  },
  {
    titulo: "Building Minimal APIs with ASP.NET Core 8 and Dapper",
    descripcion:
      "ASP.NET Core, Web APIs, SQL Server, User System, GraphQL, Redis, REST, and more!",
    idioma: IDIOMA_INGLES,
    linkUdemy:
      "https://www.udemy.com/course/building-minimal-apis-with-aspnet-core-and-dapper",
    imagenPrincipal:
      "/portadas/minimal-apis-dapper-eng.png",
      etiquetas: ['asp-net-core', 'ADO.NET'],
      prioridad: 3
  },
  {
    titulo: "Desarrollando Aplicaciones en Angular 18 y ASP.NET Core 9",
    descripcion: 'Utiliza Angular, ASP.NET Core, Entity Framework Core, Material Design, JWT, Leaflet, para crear una aplicación completa.',
    idioma: IDIOMA_ESPAÑOL,
    etiquetas: ['asp-net-core', 'angular', 'entity-framework-core'],
    linkUdemy:
      "https://www.udemy.com/course/desarrollando-aplicaciones-en-angular-y-aspnet-core-v2",
    imagenPrincipal: '/portadas/asp-net-core-angular.jpg',
    prioridad: 3
  }
];

export default cursosDatos;
