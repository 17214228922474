import { IDIOMA_ESPAÑOL, IDIOMA_INGLES } from "constantes";

const obtenerLink = (linkUdemy, idioma) => {
    if (!linkUdemy){
      return '';
    }

    if (linkUdemy.indexOf('?couponCode') !== -1){
      return linkUdemy;
    }

    const [cuponMes, monthCoupon] = obtenerCuponesAplicar();
    
    if (idioma === IDIOMA_ESPAÑOL){
      return anexarCupon(linkUdemy, cuponMes);
    } else if (idioma === IDIOMA_INGLES){
      return anexarCupon(linkUdemy, monthCoupon);
    } 

    return linkUdemy;

  };

  const obtenerCuponesAplicar = () => {
    const fechaActual = new Date();
    const mesEspañol = fechaActual.toLocaleString('es', {month: 'long'}).toUpperCase();
    const mesIngles = fechaActual.toLocaleString('en', {month: 'long'}).toUpperCase();
    const año = fechaActual.getFullYear();
    
    let cuponMes = mesEspañol + año;
    let monthCoupon = mesIngles + año;

    // A partir de mayo 2022 se cambia el formato de los cupones a año+mes
    var fechaCambioCupon = new Date(2022, 4, 1);
    if (fechaActual >= fechaCambioCupon){
      cuponMes = año + mesEspañol;
      monthCoupon = año + mesIngles;
    }

    const diaFinDescuentoMaximo = 6;
    const mesFinDescuentoMaximo = 9;
    const añoFinDescuentoMaximo = 2021;
    const fechaLimiteDescuentoMaximo = new Date(añoFinDescuentoMaximo, mesFinDescuentoMaximo-1, diaFinDescuentoMaximo, 0, 0 ,0);

    if (fechaActual < fechaLimiteDescuentoMaximo){
      // aplico cupones de descuento máximo
      cuponMes = 'SEPTIEMBRE2021-1';
      monthCoupon = 'SEPTEMBER2021-1';
    }

    return [cuponMes, monthCoupon];
  }

  const anexarCupon = (url, cupon) => `${url}?couponCode=${cupon}`;

  export default obtenerLink;