import { IDIOMA_ESPAÑOL, IDIOMA_INGLES } from "constantes";
import obtenerLink from "cursos/obtenerLinkConCupones";
import React, { useEffect, useState } from "react"
import cursosDatos from "./cursos-datos";

export default function MisLinks(){
    
    const [cursosEsp, setCursosEsp] = useState([]);
    const [cursosEng, setCursosEng] = useState([]);

    useEffect(() => {
        const cursosArr = cursosDatos.map(x => 
            {return {
                linkCupon: obtenerLink(x.linkUdemy, x.idioma),
                titulo: x.titulo,
                idioma: x.idioma,
                prioridad: x.prioridad
            }});
        
        cursosArr.sort((a,b) => b.prioridad - a.prioridad);

        const cursosEsp = cursosArr.filter(x => x.idioma === IDIOMA_ESPAÑOL);
        const cursosEng = cursosArr.filter(x => x.idioma === IDIOMA_INGLES);
        setCursosEsp(cursosEsp);
        setCursosEng(cursosEng);
    }, [])
    
    return (
        <>
        {cursosEsp.map((curso,indice) => <p key={indice}><b>{curso.titulo}</b>: {curso.linkCupon}</p>)}
        {cursosEng.map((curso,indice) => <p key={indice}><b>{curso.titulo}</b>: {curso.linkCupon}</p>)}
        </>
    )
}