const { IDIOMA_ESPAÑOL, IDIOMA_DESCONOCIDO } = require("constantes");

function todosLosCursos(idioma){
    if (esEspañolOPorDefecto(idioma)){
        return 'Todos los cursos';
    } else {
        return 'All courses';
    }
}

function comprar(idioma){
    if (esEspañolOPorDefecto(idioma)){
        return 'Comprar';
    } else {
        return 'Buy';
    }
}

function tituloCursos(idioma){
    if (esEspañolOPorDefecto(idioma)){
        return 'Cursos';
    } else {
        return 'Courses';
    }
}

function categoriaVacia(idioma){
    if (esEspañolOPorDefecto(idioma)){
        return 'No tengo cursos en esta categoría. Los siguientes son todos mis cursos:';
    } else {
        return "I don't have courses in this category. These are all my courses:";
    }
}

function mensajeFooter(idioma){
    if (esEspañolOPorDefecto(idioma)){
        return 'En esta página encontrarás mis cursos de Udemy.';
    } else {
        return "In this page you'll find my Udemy courses.";
    }
}

function esEspañolOPorDefecto(idioma){
    return idioma === IDIOMA_ESPAÑOL || idioma === IDIOMA_DESCONOCIDO;
}

export {todosLosCursos, comprar, tituloCursos, categoriaVacia, mensajeFooter}