import { IDIOMA_DESCONOCIDO, IDIOMA_ESPAÑOL, IDIOMA_INGLES } from "constantes";
import ListadoCursos from "cursos/ListadoCursos";
import IdiomaContext from "idiomaContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cursosDatos from "./cursos-datos";

export default function LandingPage() {
  const [cursosEsp, setCursosEsp] = useState(cursosDatos);
  const [cursosEng, setCursosEng] = useState(cursosDatos);
  const history = useHistory();
  const { setIdioma, idioma } = useContext(IdiomaContext);

  useEffect(() => {
    const filtradosEsp = cursosDatos
      .filter((curso) => curso.idioma === IDIOMA_ESPAÑOL)
      .slice(-3)
      .reverse();
    setCursosEsp(filtradosEsp);

    const filtradosEng = cursosDatos
      .filter((curso) => curso.idioma === IDIOMA_INGLES)
      .slice(-3)
      .reverse();
    setCursosEng(filtradosEng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navegar = (idioma) => {
    setIdioma(idioma);
    history.push("/cursos");
  };

  return (
    <>
      {idioma === IDIOMA_ESPAÑOL ? (
        <div>
          Speak english?{" "}
          <span
            onClick={() => setIdioma(IDIOMA_INGLES)}
            className="link-primary pointer"
          >
            Click to display courses in english
          </span>
        </div>
      ) : null}
      {idioma === IDIOMA_INGLES ? (
        <div>
          ¿Hablas español?{" "}
          <span
            onClick={() => setIdioma(IDIOMA_ESPAÑOL)}
            className="link-primary pointer"
          >
            Click para mostrar los cursos en español
          </span>
        </div>
      ) : null}

      {idioma === IDIOMA_ESPAÑOL || idioma === IDIOMA_DESCONOCIDO ? (
        <div style={{ marginBottom: "1rem" }}>
          <h3>
            Cursos más recientes en español (
            <span
              className="link-primary pointer"
              onClick={() => {
                navegar(IDIOMA_ESPAÑOL);
              }}
            >
              ver todos
            </span>
            )
          </h3>
          <ListadoCursos listado={cursosEsp} />
        </div>
      ) : null}

      {idioma === IDIOMA_INGLES || idioma === IDIOMA_DESCONOCIDO ? (
        <div className="bg-3">
          <h3>
            Most recent courses in english (
            <span
              className="link-primary pointer"
              onClick={() => {
                navegar(IDIOMA_INGLES);
              }}
            >
              see all
            </span>
            )
          </h3>
          <ListadoCursos listado={cursosEng} />
        </div>
      ) : null}
    </>
  );
}
